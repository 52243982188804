import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Autocomplete,
} from "@mui/material";
import { useTheme } from "../../context/ThemeContext";
import { commonThemes } from "../../utils/themes/themes";

const UserSelfService = () => {
  const { isDarkMode } = useTheme();
  const { apiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const [selectionLists, setSelectionLists] = useState({});
  const [formData, setFormData] = useState({
    LocalUser: {
      usrFirstName: "",
      usrLastName: "",
      usrFullName: "",
      usrEmail: "",
      usrPrivateEmail: "",
      usrNickName: "",
      usrPhone: "",
      usrGender: "",
      usrCountry: "",
      usrLang: "",
      usrPrefix: "",
      usrDOB: "",
    },
    UserAddress: {
      adrStreet1: "",
      adrStreet2: "",
      adrTown: "",
      adrRegion: "",
      adrPostCode: "",
      adrCountry: "",
      adrAddressee: "",
      adrType_Enum5: 0,
    },
    NextOfKinPerson: {
      pplFirstName: "",
      pplLastName: "",
      pplOtherNames: "",
      pplPrefix: "",
      pplGender: "",
      pplDOB: "",
      pplWorkEmail: "",
      pplWorkEmailUse_Enum9: 0,
      pplPrivEmail: "",
      pplPrivPhone: "",
      pplSalutation: "",
    },
    NextOfKinPersonAddress: {
      adrStreet1: "",
      adrStreet2: "",
      adrTown: "",
      adrRegion: "",
      adrPostCode: "",
      adrCountry: "",
      adrAddressee: "",
      adrType_Enum5: 0,
    },
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/user-self-service/get-user-data-async?BaseHostURL=${envConfig.mainServiceUrl}`;
      try {
        await apiCall({
          url: url,
          method: "GET",
          onSuccess: (data) => {
            setFormData(data.UserData);
            setSelectionLists(data.UserData.SelectionLists);
          },
          onError: (error) => {
            enqueueSnackbar(error, { variant: "error" });
          },
        });
      } catch (error) {
        enqueueSnackbar(error || "An unexpected error occurred", {
          variant: "error",
        });
      }
    };

    fetchUserData();
  }, [apiCall, enqueueSnackbar]);

  const handleInputChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/user-self-service/save-user-data-async`;
    try {
      await apiCall({
        url: url,
        method: "POST",
        body: formData,
        onSuccess: () => {
          enqueueSnackbar("User data updated successfully", {
            variant: "success",
          });
        },
        onError: (error) => {
          enqueueSnackbar(error, { variant: "error" });
        },
      });
    } catch (error) {
      enqueueSnackbar("An unexpected error occurred", { variant: "error" });
    }
  };

  console.log(selectionLists);
  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        User Data
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">User Information</Typography>
          <TextField
            fullWidth
            label="First Name"
            value={formData.LocalUser.usrFirstName}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrFirstName", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Last Name"
            value={formData.LocalUser.usrLastName}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrLastName", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Full Name"
            value={formData.LocalUser.usrFullName}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrFullName", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            value={formData.LocalUser.usrEmail}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrEmail", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Private Email"
            value={formData.LocalUser.usrPrivateEmail}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrPrivateEmail", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Nickname"
            value={formData.LocalUser.usrNickName}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrNickName", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Phone"
            value={formData.LocalUser.usrPhone}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrPhone", e.target.value)
            }
            margin="normal"
          />
          <Autocomplete
            fullWidth
            options={selectionLists.ListOfGenders || []}
            getOptionLabel={(option) => option.Display || ""}
            value={
              selectionLists.ListOfGenders?.find(
                (gender) => gender.Value === formData.NextOfKinPerson.pplGender,
              ) || null
            }
            onChange={(event, newValue) => {
              handleInputChange(
                "NextOfKinPerson",
                "pplGender",
                newValue ? newValue.Value : "",
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    paddingTop: "8px !important",
                    paddingBottom: "9px !important",
                  },
                  label: {
                    ...commonThemes.inputLabel,
                    color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                  },
                }}
                label="Gender"
                margin="normal"
              />
            )}
          />
          <Autocomplete
            fullWidth
            options={selectionLists.ListOfLanguages || []}
            getOptionLabel={(option) => option.Display || ""}
            value={
              selectionLists.ListOfLanguages?.find(
                (lang) => lang.Value === formData.LocalUser.usrLang,
              ) || null
            }
            onChange={(event, newValue) => {
              handleInputChange(
                "LocalUser",
                "usrLang",
                newValue ? newValue.Value : "",
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    paddingTop: "8px !important",
                    paddingBottom: "9px !important",
                  },
                  label: {
                    ...commonThemes.inputLabel,
                    color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                  },
                }}
                label="Language"
                margin="normal"
              />
            )}
          />
          <TextField
            fullWidth
            label="Prefix"
            value={formData.LocalUser.usrPrefix}
            onChange={(e) =>
              handleInputChange("LocalUser", "usrPrefix", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Date of Birth"
            type="date"
            value={
              formData.LocalUser.usrDOB
                ? formData.LocalUser.usrDOB.split("T")[0]
                : ""
            }
            onChange={(e) =>
              handleInputChange("LocalUser", "usrDOB", e.target.value)
            }
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">User Address</Typography>
          <TextField
            fullWidth
            label="Street 1"
            value={formData.UserAddress.adrStreet1}
            onChange={(e) =>
              handleInputChange("UserAddress", "adrStreet1", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Street 2"
            value={formData.UserAddress.adrStreet2}
            onChange={(e) =>
              handleInputChange("UserAddress", "adrStreet2", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Town"
            value={formData.UserAddress.adrTown}
            onChange={(e) =>
              handleInputChange("UserAddress", "adrTown", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Region"
            value={formData.UserAddress.adrRegion}
            onChange={(e) =>
              handleInputChange("UserAddress", "adrRegion", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Post Code"
            value={formData.UserAddress.adrPostCode}
            onChange={(e) =>
              handleInputChange("UserAddress", "adrPostCode", e.target.value)
            }
            margin="normal"
          />
          <Autocomplete
            fullWidth
            options={selectionLists.ListOfCountries || []}
            getOptionLabel={(option) => option.ctrName || ""}
            value={
              selectionLists.ListOfCountries?.find(
                (country) =>
                  country.ctrCodeIso === formData.UserAddress.adrCountry,
              ) || null
            }
            onChange={(event, newValue) => {
              handleInputChange(
                "UserAddress",
                "adrCountry",
                newValue ? newValue.ctrCodeIso : "",
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    paddingTop: "8px !important",
                    paddingBottom: "9px !important",
                  },
                  label: {
                    ...commonThemes.inputLabel,
                    color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                  },
                }}
                label="Country"
                margin="normal"
              />
            )}
          />
          <TextField
            fullWidth
            label="Addressee"
            value={formData.UserAddress.adrAddressee}
            onChange={(e) =>
              handleInputChange("UserAddress", "adrAddressee", e.target.value)
            }
            margin="normal"
          />
          <Autocomplete
            fullWidth
            options={selectionLists.ListOfAddressTypes || []}
            getOptionLabel={(option) => option.Display || ""}
            value={
              selectionLists.ListOfAddressTypes?.find(
                (type) => type.Value === formData.UserAddress.adrType_Enum5,
              ) || null
            }
            onChange={(event, newValue) => {
              handleInputChange(
                "UserAddress",
                "adrType_Enum5",
                newValue ? newValue.Value : 0,
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    paddingTop: "8px !important",
                    paddingBottom: "9px !important",
                  },
                  label: {
                    ...commonThemes.inputLabel,
                    color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                  },
                }}
                label="Address Type"
                margin="normal"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Next of Kin Person</Typography>
          <TextField
            fullWidth
            label="First Name"
            value={formData.NextOfKinPerson.pplFirstName}
            onChange={(e) =>
              handleInputChange(
                "NextOfKinPerson",
                "pplFirstName",
                e.target.value,
              )
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Last Name"
            value={formData.NextOfKinPerson.pplLastName}
            onChange={(e) =>
              handleInputChange(
                "NextOfKinPerson",
                "pplLastName",
                e.target.value,
              )
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Private Email"
            value={formData.NextOfKinPerson.pplPrivEmail}
            onChange={(e) =>
              handleInputChange(
                "NextOfKinPerson",
                "pplPrivEmail",
                e.target.value,
              )
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Phone"
            value={formData.NextOfKinPerson.pplPrivPhone}
            onChange={(e) =>
              handleInputChange(
                "NextOfKinPerson",
                "pplPrivPhone",
                e.target.value,
              )
            }
            margin="normal"
          />
          <Autocomplete
            fullWidth
            options={selectionLists.ListOfGenders || []}
            getOptionLabel={(option) => option.Display || ""}
            value={
              selectionLists.ListOfGenders?.find(
                (gender) => gender.Value === formData.NextOfKinPerson.pplGender,
              ) || null
            }
            onChange={(event, newValue) => {
              handleInputChange(
                "NextOfKinPerson",
                "pplGender",
                newValue ? newValue.Value : "",
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    paddingTop: "8px !important",
                    paddingBottom: "9px !important",
                  },
                  label: {
                    ...commonThemes.inputLabel,
                    color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                  },
                }}
                label="Gender"
                margin="normal"
              />
            )}
          />
          <TextField
            fullWidth
            label="Prefix"
            value={formData.NextOfKinPerson.pplPrefix}
            onChange={(e) =>
              handleInputChange("NextOfKinPerson", "pplPrefix", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Date of Birth"
            type="date"
            value={
              formData.NextOfKinPerson.usrDOB
                ? formData.NextOfKinPerson.usrDOB.split("T")[0]
                : ""
            }
            onChange={(e) =>
              handleInputChange("NextOfKinPerson", "pplDOB", e.target.value)
            }
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            label="Work Email"
            value={formData.NextOfKinPerson.pplWorkEmail}
            onChange={(e) =>
              handleInputChange(
                "NextOfKinPerson",
                "pplWorkEmail",
                e.target.value,
              )
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Salutation"
            value={formData.NextOfKinPerson.pplSalutation}
            onChange={(e) =>
              handleInputChange(
                "NextOfKinPerson",
                "pplSalutation",
                e.target.value,
              )
            }
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Next of Kin Address</Typography>
          <TextField
            fullWidth
            label="Street 1"
            value={formData.NextOfKinPersonAddress.adrStreet1}
            onChange={(e) =>
              handleInputChange(
                "NextOfKinPersonAddress",
                "adrStreet1",
                e.target.value,
              )
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Street 2"
            value={formData.NextOfKinPersonAddress.adrStreet2}
            onChange={(e) =>
              handleInputChange(
                "NextOfKinPersonAddress",
                "adrStreet2",
                e.target.value,
              )
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Town"
            value={formData.NextOfKinPersonAddress.adrTown}
            onChange={(e) =>
              handleInputChange(
                "NextOfKinPersonAddress",
                "adrTown",
                e.target.value,
              )
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Region"
            value={formData.NextOfKinPersonAddress.adrRegion}
            onChange={(e) =>
              handleInputChange(
                "NextOfKinPersonAddress",
                "adrRegion",
                e.target.value,
              )
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Post Code"
            value={formData.NextOfKinPersonAddress.adrPostCode}
            onChange={(e) =>
              handleInputChange(
                "NextOfKinPersonAddress",
                "adrPostCode",
                e.target.value,
              )
            }
            margin="normal"
          />
          <Autocomplete
            fullWidth
            options={selectionLists.ListOfGenders || []}
            getOptionLabel={(option) => option.Display || ""}
            value={
              selectionLists.ListOfGenders?.find(
                (gender) => gender.Value === formData.LocalUser.usrGender,
              ) || null
            }
            onChange={(event, newValue) => {
              handleInputChange(
                "LocalUser",
                "usrGender",
                newValue ? newValue.Value : "",
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    paddingTop: "8px !important",
                    paddingBottom: "9px !important",
                  },
                  label: {
                    ...commonThemes.inputLabel,
                    color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                  },
                }}
                label="Gender"
                margin="normal"
              />
            )}
          />
          <TextField
            fullWidth
            label="Addressee"
            value={formData.NextOfKinPersonAddress.adrAddressee}
            onChange={(e) =>
              handleInputChange(
                "NextOfKinPersonAddress",
                "adrAddressee",
                e.target.value,
              )
            }
            margin="normal"
          />
          <Autocomplete
            fullWidth
            options={selectionLists.ListOfAddressTypes || []}
            getOptionLabel={(option) => option.Display || ""}
            value={
              selectionLists.ListOfAddressTypes?.find(
                (type) =>
                  type.Value === formData.NextOfKinPersonAddress.adrType_Enum5,
              ) || null
            }
            onChange={(event, newValue) => {
              handleInputChange(
                "NextOfKinPersonAddress",
                "adrType_Enum5",
                newValue ? newValue.Value : 0,
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    paddingTop: "8px !important",
                    paddingBottom: "9px !important",
                  },
                  label: {
                    ...commonThemes.inputLabel,
                    color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.6)",
                  },
                }}
                label="Address Type"
                margin="normal"
              />
            )}
          />
        </Grid>
      </Grid>
      <Box mt={3}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default UserSelfService;
